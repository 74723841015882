@import "../../../../node_modules/react-datepicker/dist/react-datepicker.min.css";
@import "variables";
/* @see https://github.com/Hacker0x01/react-datepicker/issues/1210 */

//React Datepicker doesn't have a button by default. Using boostrap's input-group styling and some custom styles to make it work.
.date-picker-component {
  .input-group {
    .react-datepicker-wrapper {
      //Note: react-datepicker-wrapper must be display:inline block (react-datepicker's styling) to align calendar under input field appropriately.
      width: 100%;

      .react-datepicker__input-container {
        width: 100%;
      }
    }

    .input-group-btn {
      vertical-align: top; // fixes offset where button was lower than input.
    }
  }

  button.btn.btn-primary {
    &.disabled, &:disabled {
      border: 1px solid darkgrey;
    }
  }

  /* @fixes calendar displaying behind child inputs */
  .react-datepicker-popper {
    z-index: 5;
  }

  /* @fixes year & month drop-down arrow positions */
  .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow {
    top: 5px;
  }

  .react-datepicker__day--selected {
    background-color: $chr-active-blue;
  }

  /* @fixes close icon not showing / not clickable in firefox */
  /* @see https://github.com/Hacker0x01/react-datepicker/issues/1389 */
  .react-datepicker__close-icon {
    z-index: 2;

    &::after {
      background-color: $chr-navisphere-light-grey;
    }

    &:hover {
      &::after {
        background-color: $chr-active-blue;
      }
    }
  }
}
