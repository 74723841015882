@import '@chr/autoStyle/dist/sass/base/chrBase_variables';
@import 'media-queries';

$chr-overlay-blue: $navisphere-overlay-blue;
$chr-validation-yellow: $validation-yellow;

$chr-navisphere-light-blue: #108de0;
$chr-active-blue: #0071c7;
$chr-validation-green: #008015;
$chr-error-red: #c20000;
$chr-action-needed-red: #8e0000;
$chr-action-needed-orange: #b54d08;
$chr-dark-grey: #36383a;
$chr-navisphere-dark-grey: #36383a;
$chr-medium-grey: #63676a;
$chr-navisphere-medium-grey: #63676a;
$chr-placeholder-grey: #aaaeb0;
$chr-navisphere-light-grey: #d3d5d6;
$chr-light-grey: #d3d5d6;
$chr-ultra-light-grey: #eeefef;

$carrier-blue: #004986;
$carrier-mid-blue: #004986;

$carrier-off-white: #f6f7f8;
$carrier-light-grey: #e5e6e7;
$carrier-light-grey-1: #eeefef;
$carrier-light-2-grey: #d3d5d6;
$carrier-mid-grey: #d3d5d6;
$carrier-mid-grey-1: #d3d5d6;
$carrier-mid-1-grey: #aaaeb0;
$carrier-mid-2-grey: #868a8d;
$carrier-mid-3-grey: #545658;
$carrier-dark-grey: #36383a;
$carrier-med-grey: #111212;
$carrier-med-grey-2: #63676a;
$carrier-dark-2-grey: #111212;
$carrier-disabled-grey: #868a8d;
$carrier-teal: #2e8095;

$warning-color: #fdc20c;
$info-color: #005cb9;
$success-color: #008015;
$error-color: #c20000;
$error-color-secondary: #fcf3f3;
$icon-size: 13px;
$focus-state-color: #66afe9; //same as chrome's hover state color

$shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.15); // same value as autoStyle's .ns-card .shadow

/*Status Pill Colors*/
$carrier-medium-dark-green: #205823;
$carrier-medium-dark-red: #8d1d1d;
$carrier-dark-cyan: #004986;

/*Find Loads Version 2 Variables*/
$carrier-blue-lighter: #108de0;
$carrier-blue-location-pin: #108de0;
$carrier-navigation-blue: #0071c7;
$carrier-grey-location-pin: #aaaeb0;
$carrier-selected-white: #f6f7f8;
$carrier-active-light-blue: #d2e9f7;
$card-shadow: 0 0 5px rgba(0, 0, 0, 0.4);

/*CAP Version 2 Variables*/
$cap-orange: #b54d08;
$cap-orange-lighter: #eec366;

$count-down-timer: #846301;

/*Font Variables*/
$font-color: #111212;
$de-emphasized-font-color: #545658;
$placeholder-font-color: #868a8d;

//font-size variables - We are using rem units to allow for type to resize. Most browser's default base font is 16px
$font-size-xxxl: 1.625rem; //26/16=1.625
$font-size-xxl: 1.5rem; // 24/16=1.5
$font-size-xl: 1.25rem; //20/16=1.25
$font-size-lg: 1.125rem; //18/16=1.125
$font-size-md: 1rem; //16/16=1
$font-size-sm: 0.875rem; //14/16=0.875
$font-size-xs: 0.8125rem; //13/16=0.8125
$font-size-xxs: 0.75rem; //12/16=0.75

$line-height-scale: 1.5; // recommended to have line height be 1.5 times font size as minimum scale, according to accessibility guides

//font-weight variables
$lighter: 100;
$light: 200;
$normal: 400;
$bold: 600;
$bolder: 700;

%h1 {
  font-size: $font-size-xxl;
  font-weight: $normal;
  color: $font-color;
  line-height: ($font-size-xxl * $line-height-scale);
}

%h2 {
  font-size: $font-size-xl;
  font-weight: $normal;
  color: $font-color;
  line-height: ($font-size-xl * $line-height-scale);
}

%h3 {
  font-size: $font-size-sm;
  font-weight: $bold;
  color: $font-color;
  line-height: ($font-size-sm * $line-height-scale);
}

// UX specified that all h3, h4, h5, and h6 headers have the same styling
// Even though they are styled the same, we are differentiating the h4, h5, h6 in the code in case we decide on a different direction in the future

%h4,
%h5,
%h6 {
  @extend %h3;
}

//form visual design variables
$input-height: ($font-size-sm * $line-height-scale * 2); //setting height so fields are always same height (at any default browser font-size)

/*Table Grid Colors*/
$table-grid-light-grey: #f6f7f8;

/*Button Colors*/
$button-primary-blue: #0071c7;
$button-disabled-grey: #aaaeb0;
